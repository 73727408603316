import AuthService from "./AuthService";
import RestService from "./RestService";


export function defineApiService() {
  return {
    auth: new AuthService('login'),
    users: new RestService<any>('users'),
    files: new RestService<any>('files'),
    lessons: new RestService<any>('lessons'),
    lessonStudents: new RestService<any>('lesson-students'),
    lessonVideos: new RestService<any>('lesson-videos'),
    forgotPassword: new RestService<any>('forgot-password'),
    versions: new RestService<any>('app-versions'),
    lessonQuestion: new RestService<any>('questions'),
    units: new RestService<any>('units'),
  };
}

const apiService = defineApiService();

export type ApiService = ReturnType<typeof defineApiService>;

export function apiRequest() {
  return {
    apiService,
  };
}