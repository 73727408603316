import React, { useEffect, useState } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { useTranslation } from '@/config/i18n';
import { MENU_ITEMS } from '@/shared/constants/menu';
import { getVisibleMenu } from '@/lib/utils';
import usePathName from '@/hooks/usePathName';
import Logo from '@/components/common/logo';
import PATH from '@/routes/path';
import { useSelectorUserInfo } from '@/components/auth/AuthContext';
import { SidebarFooter } from './SideBarFooter';
import { IconArrowUp } from '@/components/icons';

type Props = {
  isOpen: boolean;
  toggleSidebar: () => void;
};

const LeftSideBar: React.FC<Props> = ({ isOpen, toggleSidebar }) => {
  const [t] = useTranslation('');
  const pathname = usePathName();
  const [open, setOpen] = useState<'setting' | undefined>();
  const { currentUser } = useSelectorUserInfo();
  const navigate = useNavigate();

  const handleOpenSubMenu = (key?: 'setting') => {
    if (open === key) {
      return;
    }

    if (key === 'setting') {
      navigate(PATH.version_management);
    }

    setOpen(key);
  };

  useEffect(() => {
    if([PATH.version_management].includes(pathname)) {
      setOpen('setting');
    }
  }, [pathname]);

  return (
    <div className="flex flex-col fixed z-40 bg-primary-900">
      <Sidebar
        collapsed={!isOpen}
        backgroundColor="none"
        className="bg-shipGray h-[calc(100vh-171px)] transition-all duration-300"
        collapsedWidth="76px"
        width="280px"
      >
        <div className="logo flex-full-center my-[20px]">
          <Link to={{ pathname: PATH.home }}>
            <Logo />
          </Link>
        </div>
        <Menu>
          {getVisibleMenu(MENU_ITEMS, currentUser?.current_roles).map(
            (item, index) => {
              const isActive = pathname.includes(item.link);
              const Icon = item.icon;
              const { children } = item;
              return (
                <div
                  key={index}
                  className={`${isActive ? 'sidebar-menu-item-active' : 'sidebar-menu-item'}`}
                >
                  {!!children && children?.length > 0 ? (
                    <SubMenu
                      icon={Icon ? <Icon /> : null}
                      label={t(item.title)}
                      open={open === 'setting'}
                      onClick={() => handleOpenSubMenu('setting')}
                      className="text-primary-100"
                      rootStyles={{
                        ['.ps-submenu-content']: {
                          backgroundColor: 'transparent',
                        },
                        ['.ps-menu-button']: {
                          height: '40px',
                        },
                      }}
                      suffix={<IconArrowUp />}
                    >
                      {children.map((child) => (
                        <MenuItem
                          className={classNames('text-primary-100 mt-[4px] h-[40px] pl-[25px]', {
                            '!bg-primary-800 rounded-[16px] active-item': pathname.includes(child.link),
                          })}
                          icon={child.icon || null}
                          component={<Link to={{ pathname: child.link }} />}
                        >
                          {t(child.title)}
                        </MenuItem>
                      ))}
                    </SubMenu>
                  ) : (
                    <MenuItem
                      className="text-primary-100"
                      icon={Icon ? <Icon /> : null}
                      component={<Link to={{ pathname: item.link }} />}
                      active={isActive}
                      onClick={() => handleOpenSubMenu()}
                    >
                      {t(item.title)}
                    </MenuItem>
                  )}
                </div>
              );
            },
          )}
        </Menu>
      </Sidebar>

      <SidebarFooter collapsed={isOpen} toggleSidebar={toggleSidebar} />
    </div>
  );
};

export default LeftSideBar;
