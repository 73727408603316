import PATH from '@/routes/path';
import axios, { AxiosRequestConfig } from 'axios';
import i18n from '@/config/i18n';

export interface AxiosOption extends AxiosRequestConfig {
  query?: any;
  body?: any;
}
export type AxiosReturn<T> = {
  status?: number;
  data: T | null;
  pagination?: any;
  error: any;
  loading: boolean;
};

class MailForgot {
  private _mail: any = '';

  get value() {
    return this._mail || sessionStorage.getItem('mail');
  }
  set value(mail: string) {
    this._mail = mail;
    sessionStorage.setItem('mail', mail);
  }
}

export const mailForgot = new MailForgot();

const axiosCustom = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axiosCustom.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const tokenExpiresAt = parseInt(localStorage.getItem('tokenExpiresAt') || '0');

    if (
      !window.location.pathname?.includes(PATH.login) ||
      (config?.url?.includes('/me') &&
        window.location.pathname?.includes(PATH.login))
    ) {
      if (tokenExpiresAt && Date.now() >= tokenExpiresAt) {
        if (!isRefreshing) {
          isRefreshing = true;
          try {
            const { data: response } = await axios.post(`${process.env.REACT_APP_API_URL}refresh`, { refresh_token: refreshToken });
            localStorage.setItem('accessToken', response.data.access_token);
            localStorage.setItem('refreshToken', response.data.refresh_token);
            localStorage.setItem('tokenExpiresAt', (Date.now() + response.data.expires_in * 1000).toString());
            config.headers['Authorization'] = `Bearer ${response.data.access_token}`;
            processQueue(null, response.data.access_token);
          } catch (error) {
            processQueue(error, null);
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('tokenExpiresAt');
            window.location.href = '/login';
            return Promise.reject(error);
          } finally {
            isRefreshing = false;
          }
        } else {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              config.headers['Authorization'] = `Bearer ${token}`;
              return config;
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }
      } else {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosCustom.interceptors.response.use(
  (response) => {
    localStorage.removeItem('error_503');
    return response;
  },
  async (error) => {
    if (error.code === 'ERR_NETWORK') {
      (window as any).PrimeToast.show({
        severity: "error",
        summary: i18n.t('common.toast.no_internet'),
      });
    }

    const originalRequest = error.config;

    if (
      !window.location.pathname?.includes(PATH.login) ||
      (originalRequest?.url?.includes('/me') &&
        window.location.pathname?.includes(PATH.login))
    ) {
      if (error?.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshToken = localStorage.getItem('refreshToken');
        const tokenExpiresAt = parseInt(localStorage.getItem('tokenExpiresAt') || '0');
        if (refreshToken && tokenExpiresAt) {
          try {
            const { data: response } = await axios.post(`${process.env.REACT_APP_API_URL}refresh`, { refresh_token: refreshToken });
            localStorage.setItem('accessToken', response.data.access_token);
            localStorage.setItem('refreshToken', response.data.refresh_token);
            localStorage.setItem('tokenExpiresAt', (Date.now() + response.data.expires_in * 1000).toString());
            axiosCustom.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`;
            originalRequest.headers['Authorization'] = `Bearer ${response.data.access_token}`;
            return axiosCustom(originalRequest);
          } catch (error) {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('tokenExpiresAt');
            window.location.href = '/login';
            return Promise.reject(error);
          }
        } else {
          window.location.href = '/login';
          return Promise.reject(error);
        }
      }
    }
    

    if (error?.response?.status === 403) {
      window.location.href = '/403';
    }

    if (error?.response?.status === 429) {
      (window as any).PrimeToast.show({
        severity: "error",
        summary: i18n.t('common.toast.too_many_requests'),
      });
    }

    if (error?.response?.status > 500 && error?.response?.status !== 503) {
      window.location.href = '/500';
    }

    if (error?.response?.status === 503) {
      const { message, time } = error?.response?.data || {};
      const data = {
        message,
        time,
      }
      localStorage.setItem('error_503', JSON.stringify(data));
      window.location.href = `/503`;
    }

    return Promise.reject(error);
  }
);


const axiosRequest = async <T = any>(
  url: string,
  options?: AxiosOption,
): Promise<AxiosReturn<T>> => {
  const defaultUrl = `${process.env.REACT_APP_API_URL}${url}`
  const filteredQuery = Object.fromEntries(
    Object.entries(options?.query || {}).filter(([, value]) => value !== undefined && value !== null && value !== '')
  ) as unknown as any;
  const customUrl =
    options?.query && options.method === 'GET'
      ? `${!defaultUrl.endsWith('?') ? `${defaultUrl}?` : defaultUrl}` + new URLSearchParams(filteredQuery).toString()
      : defaultUrl;
  
  let data: any = null;
  let error: any = null;
  let loading = false;

  try {
    loading = true;
    const result = await axiosCustom({
      url: customUrl,
      data: options?.method === 'POST' || options?.method === 'PUT' && options.body ? options.body : null,
      ...options,
    });
    data = result.data;
    error = null
  } catch (e: any) {
    data = null;
    error = e.response?.data?.error ?? 'error';
  } finally {
    loading = false;
  }

  return { data, error, loading }
}

export default axiosRequest;