import { Button } from 'primereact/button';
import { ColumnDescription } from '@/components/common/ui/table/ColumnDescription';
import dayjs from 'dayjs';
import { InputSwitch } from 'primereact/inputswitch';

export const UserColumns = (
  t: any,
  onEditUser?: (id: number) => void,
  onEditStatusUser?: (isActive: boolean, rowData: any) => void,
): ColumnDescription[] => [
  {
    dataField: 'full_name_kanji',
    text: t('user_list.full_name'),
    classname: 'w-[200px]',
  },
  {
    dataField: 'email',
    text: t('user_list.email'),
    classname: 'w-[200px]',
  },
  {
    dataField: 'mentor',
    text: t('user_list.mentor'),
    classname: 'w-[200px]',
    formatter: (rowData) => (
      <div>
        { rowData.mentor ? rowData.mentor.email : '' }
      </div>
    ),
  },
  {
    dataField: 'created_at',
    text: t('user_list.updated_at'),
    classname: 'w-[200px]',
    sort: true,
    formatter: (rowData) => (
      <div>
        { rowData.created_at ? dayjs(rowData.created_at.toString()).format('YYYY/MM/DD') : '' }
      </div>
    ),
  },
  {
    dataField: 'action',
    text: '',
    sort: false,
    classname: 'text-center [&>div]:justify-center w-[160px]',
    headerClassname: '[&>div]:justify-center',
    formatter: (rowData) => (
      <div className="flex flex-row h-[36px] items-center">
        <InputSwitch
          checked={rowData.status === 'active' ? true : false}
          onChange={(e) => onEditStatusUser?.(e.value, rowData)}
        />
        <Button className="mx-3 p-[10px]" onClick={() => onEditUser?.(rowData.id)}>
          <span className='pi pi-pencil' />
        </Button>
      </div>
    ),
  },
];
