import { Suspense } from 'react';
import { useRoutes, RouteObject, Navigate } from 'react-router-dom';
import { addLocale } from 'primereact/api';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import PATH from './routes/path';
import RoleProtectedRoute from '@/components/shared/RoleProtectedRoute';
import AuthenticatedLayout from '@/components/shared/AuthenticatedLayout';
import Loader from './components/common/loader/Loader';
import StudentManagementPage from '@/pages/student-management';
import CoachManagementPage from '@/pages/coach-management';
import StudentManagementEditPage from '@/pages/student-management/edit';
import LoginPage from '@/pages/login';
import ForgotPasswordPage from '@/pages/forgot-password';
import StudentManagementNewPage from '@/pages/student-management/new';
import CoachManagementNewPage from '@/pages/coach-management/new';
import CoachManagementEditPage from '@/pages/coach-management/edit';
import ForbiddenPage from '@/pages/403';
import NotFoundPage from '@/pages/404';
import InternalServerErrorPage from '@/pages/500';
import ResetPasswordPage from '@/pages/reset-password';
import Lesson from '@/pages/lesson/';
import LessonNewPage from '@/pages/lesson/new';
import { ROLE } from './shared/constants/user';
import LessonEditPage from './pages/lesson/edit';
import ResetPasswordSuccessPage from './pages/reset-password-success';
import VersionManagementPage from './pages/version-management';
import { useTranslation } from './config/i18n';
import MaintainPage from './pages/503';
import LessonProgress from './pages/lesson/progress/LessonProgress';

function AppRouter() {
  const [t] = useTranslation('');

  addLocale('ja', {
    firstDayOfWeek: 0,
    dayNames: [
      '日曜日',
      '月曜日',
      '火曜日',
      '水曜日',
      '木曜日',
      '金曜日',
      '土曜日',
    ],
    dayNamesShort: ['日', '月', '火', '水', '木', '金', '土'],
    dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
    monthNames: [
      '1月',
      '2月',
      '3月',
      '4月',
      '5月',
      '6月',
      '7月',
      '8月',
      '9月',
      '10月',
      '11月',
      '12月',
    ],
    monthNamesShort: [
      '1月',
      '2月',
      '3月',
      '4月',
      '5月',
      '6月',
      '7月',
      '8月',
      '9月',
      '10月',
      '11月',
      '12月',
    ],
    today: '今日',
    clear: 'クリア',
    dateFormat: 'yy/mm/dd',
    weekHeader: '週',
  });

  const routesForPublic = [
    {
      path: '',
      element: (<Navigate to={PATH.login} />)
    },
    {
      path: PATH.login,
      element: (
        <>
          <Helmet>
            <title>{t('common.system_title')}</title>
          </Helmet>
          <LoginPage />
        </>
      ),
    },
    {
      path: PATH.forgot_password,
      element: (
        <>
          <Helmet>
            <title>{t('common.system_title')}</title>
          </Helmet>
          <ForgotPasswordPage />
        </>
      ),
    },
    {
      path: PATH.reset_password,
      element: (
        <>
          <Helmet>
            <title>{t('common.system_title')}</title>
          </Helmet>
          <ResetPasswordPage />
        </>
      ),
    },
    {
      path: PATH.reset_password_success,
      element: (
        <>
          <Helmet>
            <title>{t('common.system_title')}</title>
          </Helmet>
          <ResetPasswordSuccessPage />
        </>
      ),
    },
    {
      path: PATH.forbidden,
      element: (
        <>
          <Helmet>
            <title>{t('common.system_title')}</title>
          </Helmet>
          <ForbiddenPage />
        </>
      ),
    },
    {
      path: PATH.internal_server_error,
      element: (
        <>
          <Helmet>
            <title>{t('common.system_title')}</title>
          </Helmet>
          <InternalServerErrorPage />
        </>
      ),
    },
    {
      path: PATH.maintain,
      element: (
        <>
          <Helmet>
            <title>{t('common.system_title')}</title>
          </Helmet>
          <MaintainPage />
        </>
      ),
    },
    {
      path: '*',
      element: (
        <>
          <Helmet>
            <title>{t('common.system_title')}</title>
          </Helmet>
          <NotFoundPage />
        </>
      ),
    },
  ];

  const routesForAuthenticated: RouteObject[] = [
    {
      path: '/',
      element: (
        <RoleProtectedRoute requiredRoles={[ROLE.SUPPER_ADMIN, ROLE.ADMIN]}>
          <Navigate to={PATH.coach_management} />
        </RoleProtectedRoute>
      ),
    },
    {
      path: PATH.student_management,
      element: (
        <RoleProtectedRoute
          requiredRoles={[ROLE.SUPPER_ADMIN, ROLE.ADMIN, ROLE.TEACHER]}
        >
          <AuthenticatedLayout />
        </RoleProtectedRoute>
      ),
      children: [
        {
          path: '',
          element: (
            <>
              <Helmet>
                <title>{t('menu.student_management')}</title>
              </Helmet>
              <StudentManagementPage />
            </>
          ),
        },
        {
          path: PATH.student_management_new,
          element: (
            <>
              <Helmet>
                <title>{t('user.new_student')}</title>
              </Helmet>
              <StudentManagementNewPage />
            </>
          ),
        },
        {
          path: `${PATH.student_management_edit}/:id`,
          element: (
            <>
              <Helmet>
                <title>{t('user.edit_student')}</title>
              </Helmet>
              <StudentManagementEditPage />
            </>
          ),
        },
      ],
    },
    {
      path: PATH.coach_management,
      element: (
        <RoleProtectedRoute requiredRoles={[ROLE.SUPPER_ADMIN, ROLE.ADMIN]}>
          <AuthenticatedLayout />
        </RoleProtectedRoute>
      ),
      children: [
        {
          path: '',
          element: (
            <>
              <Helmet>
                <title>{t('menu.coach_management')}</title>
              </Helmet>
              <CoachManagementPage />
            </>
          ),
        },
        {
          path: PATH.coach_management_new,
          element: (
            <>
              <Helmet>
                <title>{t('user.new_coach')}</title>
              </Helmet>
              <CoachManagementNewPage />
            </>
          ),
        },
        {
          path: `${PATH.coach_management_edit}/:id`,
          element: (
            <>
              <Helmet>
                <title>{t('user.edit_coach')}</title>
              </Helmet>
              <CoachManagementEditPage />
            </>
          ),
        },
      ],
    },
    {
      path: PATH.lesson,
      element: (
        <RoleProtectedRoute
          requiredRoles={[ROLE.SUPPER_ADMIN, ROLE.ADMIN, ROLE.TEACHER]}
        >
          <AuthenticatedLayout />
        </RoleProtectedRoute>
      ),
      children: [
        {
          path: '',
          element: (
            <>
              <Helmet>
                <title>{t('menu.lesson')}</title>
              </Helmet>
              <Lesson />
            </>
          ),
        },
        {
          path: PATH.lesson_new,
          element: (
            <RoleProtectedRoute requiredRoles={[ROLE.SUPPER_ADMIN, ROLE.ADMIN]}>
              <Helmet>
                <title>{t('lesson_list.new')}</title>
              </Helmet>
              <LessonNewPage />
            </RoleProtectedRoute>
          ),
        },
        {
          path: `${PATH.lesson_edit}/:id`,
          element: (
            <>
              <Helmet>
                <title>{t('lesson.edit_title')}</title>
              </Helmet>
              <LessonEditPage />
            </>
          ),
        },
        {
          path: `${PATH.lesson}/:id/progress`,
          element: (
            <>
              <Helmet>
                <title>{t('lesson.edit_title')}</title>
              </Helmet>
              <LessonProgress />
            </>
          ),
        },
      ],
    },
    {
      path: PATH.version_management,
      element: (
        <RoleProtectedRoute requiredRoles={[ROLE.SUPPER_ADMIN]}>
          <AuthenticatedLayout />
        </RoleProtectedRoute>
      ),
      children: [
        {
          path: '',
          element: (
            <>
              <Helmet>
                <title>{t('version.title')}</title>
              </Helmet>
              <VersionManagementPage />
            </>
          ),
        },
      ],
    },
  ];

  const routes = [...routesForPublic, ...routesForAuthenticated];

  const routing = useRoutes(routes);

  return (
    <HelmetProvider>
      <Suspense fallback={<Loader isLoading={true} />}>{routing}</Suspense>
    </HelmetProvider>
  );
}

export default AppRouter;
