import { PrimeReactProvider } from 'primereact/api';
import '@/assets/scss/main.scss';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import 'primeicons/primeicons.css';
import AppRouter from './AppRouter';
import { LoadingProvider } from './components/common/loader/LoadingContext';
import { CommonToast } from './components/common/CommonToast';
import { AuthProvider } from './components/auth/AuthContext';

function App() {
  return (
    <AuthProvider>
      <LoadingProvider>
        <PrimeReactProvider value={{ unstyled: false }}>
          <CommonToast>
            <AppRouter />
          </CommonToast>
        </PrimeReactProvider>
      </LoadingProvider>
    </AuthProvider>
  );
}

export default App;
