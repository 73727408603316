import * as yup from 'yup';
import {
  BaseYup,
  MAX_INPUT,
  emailRegExp,
  kanjiKanaFullHiraganaRegExp,
  katakanaRegExp,
  passwordRegExp,
  postCodeRegExp,
} from '@/config/baseYup';
import i18n from '@/config/i18n';

export const userFormSchema = BaseYup.object({
  status: BaseYup.boolean().label(i18n.t('user.status')),
  require_password_update: BaseYup.boolean(),
  is_student: BaseYup.boolean(),
  profile_image_id: BaseYup.number(),
  email: BaseYup.string()
    .label(i18n.t('user.email'))
    .required()
    .matches(emailRegExp, {
      message: i18n.t('common.form.email_invalid'),
      excludeEmptyString: true,
    })
    .max(MAX_INPUT),
  phone_number: BaseYup.string().nullable()
    .label(i18n.t('user.phone_number'))
    .required()
    .min(10, i18n.t('common.form.phone_number_length')).max(15, i18n.t('common.form.phone_number_length')),
  password: BaseYup.string()
    .label(i18n.t('user.password'))
    .when('require_password_update', ([require_password_update], schema) => {
      return require_password_update === false
        ? schema.optional().nullable()
        : schema.required().min(8, i18n.t('common.form.password_length')) .max(32, i18n.t('common.form.password_length')).matches(passwordRegExp, {
          message: i18n.t('common.form.password_invalid'),
        });
    }),
  password_confirmation: BaseYup.string()
    .label(i18n.t('user.password'))
    .when('require_password_update', ([require_password_update], schema) => {
      return require_password_update === false
        ? schema.optional().nullable()
        : schema.required().oneOf(
          [BaseYup.ref('password')],
          i18n.t('common.form.password_confirmation_invalid'),
        ).min(8, i18n.t('common.form.password_length')).max(32, i18n.t('common.form.password_length')).matches(passwordRegExp, {
          message: i18n.t('common.form.password_invalid'),
        });
    }),
  mentor_id: BaseYup.string()
    .label(i18n.t('user.mentor'))
    .when('is_student', ([is_student], schema) => {
      return is_student === false
        ? schema.optional().nullable()
        : schema.required().transform(function(_value, originalValue) {
          if (typeof originalValue === 'object' && originalValue !== null) {
            return originalValue.value;
          }
          return originalValue;
        });
    }),
  graduation_terms: BaseYup.number().label(i18n.t('user.graduation_terms')).when('is_student', ([is_student], schema) => {
    return is_student === true
      ? schema.optional().nullable()
      : schema.required(i18n.t('common.form.graduation_invalid'))
        .transform(function(_value, originalValue) {
          if (typeof originalValue === 'object' && originalValue !== null) {
            return originalValue.value;
          }
          return originalValue;
        })
  }).transform((value) => Number.isNaN(value) ? null : value ),
  first_name_kanji: BaseYup.string()
    .label(i18n.t('user.first_name_kanji'))
    .required()
    .matches(kanjiKanaFullHiraganaRegExp, {
      message: i18n.t('common.form.kanji_kana_hira_invalid', {
        label_name: i18n.t('user.first_name_kanji'),
      }),
    })
    .max(MAX_INPUT),
  last_name_kanji: BaseYup.string()
    .label(i18n.t('user.last_name_kanji'))
    .required()
    .matches(kanjiKanaFullHiraganaRegExp, {
      message: i18n.t('common.form.kanji_kana_hira_invalid', {
        label_name: i18n.t('user.last_name_kanji'),
      }),
    })
    .max(MAX_INPUT),
  first_name: BaseYup.string()
    .label(i18n.t('user.first_name'))
    .required()
    .matches(katakanaRegExp, {
      message: i18n.t('common.form.katakana_invalid', {
        label_name: i18n.t('user.first_name'),
      }),
    })
    .max(MAX_INPUT),
  last_name: BaseYup.string()
    .label(i18n.t('user.last_name'))
    .required()
    .matches(katakanaRegExp, {
      message: i18n.t('common.form.katakana_invalid', {
        label_name: i18n.t('user.last_name'),
      }),
    })
    .max(MAX_INPUT),
  date_of_birth: BaseYup.string()
    .nullable()
    .label(i18n.t('user.date_of_birth'))
    .max(MAX_INPUT),
  gender: BaseYup.string().nullable().label(i18n.t('user.gender')).max(MAX_INPUT).transform(function(_value, originalValue) {
    if (typeof originalValue === 'object' && originalValue !== null) {
      return originalValue.value;
    }
    return originalValue;
  }),
  post_code: BaseYup.string()
    .required()
    .label(i18n.t('user.post_code'))
    .min(5, i18n.t('common.form.post_code_length'))
    .max(32, i18n.t('common.form.post_code_length'))
    .matches(postCodeRegExp, {
      message: i18n.t('common.form.post_code_invalid', {
        label_name: i18n.t('user.post_code'),
      }),
    }),
  address: BaseYup.string()
    .label(i18n.t('user.address'))
    .required()
    .max(MAX_INPUT),
});

export const userSearchFormSchema = BaseYup.object({
  keyword: BaseYup.string().max(MAX_INPUT),
  status: BaseYup.string().max(MAX_INPUT).nullable(),
  role: BaseYup.string().max(MAX_INPUT),
  start_date: BaseYup.date().nullable(),
  end_date: BaseYup.date().nullable(),
});

export type UserFormSearchType = yup.InferType<typeof userSearchFormSchema>;
export type UserFormType = yup.InferType<typeof userFormSchema>;
