const PATH = {
  login: '/login',
  forgot_password: '/forgot-password',
  reset_password: '/reset-password',
  reset_password_success: '/reset-password-success',
  home: '/',
  student_management: '/student-management',
  student_management_new: 'new',
  student_management_edit: 'edit',
  coach_management: '/coach-management',
  coach_management_new: 'new',
  coach_management_edit: 'edit',
  lesson: '/lesson',
  lesson_new: 'new',
  lesson_edit: 'edit',
  forbidden: '/403',
  not_found: '/404',
  internal_server_error: '/500',
  version_management: '/version-management',
  maintain: '/503',
};

export default PATH;