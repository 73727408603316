import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import classNames from 'classnames';

import TopBar from './TopBar';
import LeftSideBar from './LeftSideBar';
import { useLoading } from '@/components/common/loader/LoadingContext';
import Loader from '../common/loader/Loader';
import useScrollToTop from '@/hooks/useScrollToTop';

const AuthenticatedLayouts: React.FC = () => {
  const { loadingGlobal } = useLoading();

  const [sidebarOpen, setSideBarOpen] = useState(true);
  const handleToggleSidebar = () => {
    setSideBarOpen(!sidebarOpen);
  };

  useScrollToTop();

  const rightSideBarClasses = classNames('flex', 'transform', 'duration-300', 'transition-all', {
    'ml-[280px] w-[calc(100vw-280px)]': sidebarOpen,
    'ml-[80px] w-[calc(100vw-80px)]': !sidebarOpen,
  });

  return (
    <div className="min-h-screen bg-primary-900 pt-[10px]">
      {loadingGlobal && <Loader isLoading={loadingGlobal} />}
      <TopBar />
      <div className="flex opacity-1 transition-opacity duration-100 ease-in" style={{ opacity: loadingGlobal ? 0.9 : 1 }}>
        <LeftSideBar isOpen={sidebarOpen} toggleSidebar={handleToggleSidebar} />
        <div
          className={rightSideBarClasses}
        >
          <div className="py-3 w-full bg-white px-[20px] rounded-tl-[40px] min-h-screen">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthenticatedLayouts;
