import { JAPANESE_FULL_STOP, TOAST_LIFE } from '@/shared/constants';
import { MenuItem } from '@/types/menu';

export const getVisibleMenu = (menuItems: MenuItem[], userRoles: string[]) => {
  return (menuItems || []).filter(
    (menu) =>
      !(menu.hidden ||
      !userRoles?.some((role: string) => menu?.roles?.includes(role))),
  );
};

export function sleep(ms: number): Promise<number> {
  return new Promise((resolve) => {
    const timeoutId = setTimeout(() => {
      resolve(Number(timeoutId));
    }, ms);
  });
}

export const fetchAddressByZipcode = async (zipCode: string) => {
  const url = `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${zipCode}`;

  try {
    const response = await fetch(url);
    const data = (await response.json()) as {
      results?: any[];
    };
    return data.results?.[0] ?? null;
  } catch (error) {
    return null;
  }
};

export const standardizeSentence = (str: string) =>
  !str || str.endsWith(JAPANESE_FULL_STOP) ? str : str + JAPANESE_FULL_STOP;

const formatMessage = (message: any) =>
  typeof message === 'string' ? standardizeSentence(message) : message;

export const toastMessage = (message: string) => {
  return {
    severity: 'success',
    detail: formatMessage(message),
    life: TOAST_LIFE,
  };
};

export const toastErrorMessage = (message: any) => {
  return {
    severity: 'error',
    detail: formatMessage(message),
    life: TOAST_LIFE,
  };
};

export const toastInfoMessage = (message: string) => {
  return {
    severity: 'info',
    detail: formatMessage(message),
    life: TOAST_LIFE,
  };
};

export const serializeFormQuery = (values: any) => {
  if (!values) return {};

  return Object.fromEntries(
    Object.entries(values || {}).filter(
      ([, value]) => value !== undefined && value !== null && value !== '',
    ),
  ) as unknown as any;
};

/* eslint-disable no-bitwise */
export function randomUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
/* eslint-enable no-bitwise */

export function download(source: Blob | string, filename: string) {
  const url = typeof source === 'string' ? source : URL.createObjectURL(source);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  typeof source !== 'string' && window.URL.revokeObjectURL(url);
  a.remove();
}

export function toCsv(data: string | (string | number)[][]) {
  const csv =
    typeof data === 'string'
      ? data
      : data.map((row) => row.join(',')).join('\n');
  return new Blob([csv], { type: 'text/csv;charset=utf-8' });
}

export function getVideoCover(file: any, seekTo = 0.0): Promise<any> {
  return new Promise((resolve, reject) => {
    const videoPlayer = document.createElement('video');
    const videoUrl = URL.createObjectURL(file);
    videoPlayer.setAttribute('src', videoUrl);
    videoPlayer.load();

    videoPlayer.addEventListener('error', () => {
      reject('Error when loading video file');
    });

    videoPlayer.addEventListener('loadedmetadata', () => {
      if (videoPlayer.duration < seekTo) {
        reject('Video is too short.');
        return;
      }

      setTimeout(() => {
        videoPlayer.currentTime = seekTo;
      }, 200);

      videoPlayer.addEventListener('seeked', () => {
        const canvas = document.createElement('canvas');
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;

        const ctx = canvas.getContext('2d');
        ctx?.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);

        canvas.toBlob((blob) => {
          if (blob) {
            const imageUrl = URL.createObjectURL(blob);
            const imageFile: any = new File([blob], file.name.split('.')[0], { type: 'image/jpeg' });
            imageFile.objectURL = imageUrl;
            resolve({
              file: imageFile,
              url: imageUrl,
              video_duration: videoPlayer.duration
            });
          } else {
            reject('Error creating image blob');
          }
        }, 'image/jpeg', 0.75);
      });
    });
  });
}

export function fileToObject(file: any) {
  return {
    name: file.name,
    type: file.type,
    size: file.size,
    objectURL: file.objectURL,
  };
}

export function getValueFromSelect(values: any, keys: string[]) {
  const newValues: Record<string, any> = {};
  keys.forEach((key) => {
    if (values[key]) {
      newValues[key] = values[key].value
    }
  });
  return newValues;
}

export function byteConverter(
  bytes: number,
  decimals: number | undefined,
  only: string,
) {
  const K_UNIT = 1024;
  const SIZES = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

  if (bytes == 0) return '0 Byte';

  if (only === 'MB')
    return (bytes / (K_UNIT * K_UNIT)).toFixed(decimals) + ' MB';

  const i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
  const resp =
    parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimals)) +
    ' ' +
    SIZES[i];

  return resp;
}

export function byteToMb(sizeInBytes: number) {
  if (!sizeInBytes) return;
  const sizeInMB = sizeInBytes / (1024 * 1024);
  return sizeInMB.toFixed(2);
}

export function utf8ToBase64(str: string) {
  const encoder = new TextEncoder();
  const bytes = encoder.encode(str);
  const binaryString = String.fromCharCode(...bytes as unknown as number[]);
  return btoa(binaryString);
}

export function base64ToUtf8(base64: string) {
  const binaryString = atob(base64);
  const bytes = Uint8Array.from(binaryString, char => char.charCodeAt(0));
  const decoder = new TextDecoder();
  return decoder.decode(bytes);
}
