const IconAvatarComment = ({
  containerClassName,
}: {
  containerClassName?: string;
}) => {
  return (
    <div
      className={containerClassName}
    >
      <svg
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="p-[4px] h-full w-full"
      >
        <path
          d="M24.24 25.56C24.1 25.54 23.92 25.54 23.76 25.56C20.24 25.44 17.44 22.56 17.44 19.02C17.44 15.4 20.36 12.46 24 12.46C27.62 12.46 30.56 15.4 30.56 19.02C30.54 22.56 27.76 25.44 24.24 25.56Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M37.48 38.7601C33.92 42.0201 29.2 44.0001 24 44.0001C18.8 44.0001 14.08 42.0201 10.52 38.7601C10.72 36.8801 11.92 35.0401 14.06 33.6001C19.54 29.9601 28.5 29.9601 33.94 33.6001C36.08 35.0401 37.28 36.8801 37.48 38.7601Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export default IconAvatarComment;
