import dayjs from 'dayjs';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  TabPanel,
  TabPanelHeaderTemplateOptions,
  TabView,
} from 'primereact/tabview';
import { DataTableRowReorderEvent } from 'primereact/datatable';

import { useLoading } from '@/components/common/loader/LoadingContext';
import { apiRequest } from '@/services';
import { ToastContext } from '@/components/common/CommonToast';
import { useTranslation } from '@/config/i18n';
import { InputTextCustomForm } from '@/components/common/ui/input/Input';
import ButtonCustom from '@/components/common/ui/button';
import { RadioButtonCustomForm } from '@/components/common/ui/radio';
import { UploadFile, UploadVideo } from '@/components/common/ui/upload';
import { addNewVideoLessonSchema } from '@/shared/schema/video-lesson';
import { randomUUID, toastErrorMessage, toastMessage } from '@/lib/utils';
import { ADD_VIDEO_TEMPORARY, VIDEO_TYPE } from '@/shared/constants/video';
import { useSelectorUserInfo } from '@/components/auth/AuthContext';
import { useUploadVideoInfo } from '@/pages/lesson/context/UploadVideoContext';
import { CustomDataTable } from '@/components/common/ui/table/CustomDataTable';
import { LessonQuestionColumns } from '@/pages/lesson/table/LessonQuestionColumns';
import AddQuestionForm from './AddQuestionForm';
import DialogCustom from '@/components/common/ui/dialog';
import { handleFormatQuestion, updatePrioritiesWhenDragging } from './utils';
import { ADD_QUESTION_TEMPORARY, NO_PUBLIC_QUESTION } from '@/shared/constants';

interface IAddVideoFormProps {
  visible?: boolean;
  onHide: (unitId?: string, questions?: any) => void;
  onSubmitVideo: (videos: any) => void;
  id?: string | number;
  video?: any;
  lessonId?: string | number;
}

const statusOptions = [
  {
    label: '公開',
    value: 'public',
  },
  {
    label: '非公開',
    value: 'draft',
  },
];

const MAX_QUESTION_DISPLAY = 5;

const AddVideoForm = ({
  visible,
  onHide,
  onSubmitVideo,
  id,
  video,
  lessonId,
}: IAddVideoFormProps) => {
  const { currentUser } = useSelectorUserInfo();
  const [t] = useTranslation('');
  const { setLoadingGlobal } = useLoading();
  const { apiService } = apiRequest();
  const { toast } = useContext(ToastContext);
  const [imageFile, setImageFile] = useState<any>();
  const [imageFileGuide, setImageFileGuide] = useState<any>();
  const [questions, setQuestions] = useState<any[]>([]);
  const [visibleQuestion, setVisibleQuestion] = useState<boolean>();
  const [question, setQuestion] = useState<any>();
  const [questionId, setQuestionId] = useState<string | number>();
  const [selectedQuestion, setSelectedQuestion] = useState<any>();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [isChangeStatus, setIsChangeStatus] = useState(false);
  const {
    videoUploading,
    setVideoUploading,
    workUploading,
    setWorkUploading,
    pdf1Uploading,
    setPdf1Uploading,
    pdf2Uploading,
    setPdf2Uploading,
    pdf3Uploading,
    setPdf3Uploading,
  } = useUploadVideoInfo();

  const formMethods = useForm<any>({
    mode: 'onChange',
    defaultValues: {
      status: 'public',
    },
    resolver: yupResolver(addNewVideoLessonSchema),
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
    reset,
    setError,
    watch,
  } = formMethods;

  const statusForm = watch('status');

  const isHavePublicQuestion = useMemo(() => {
    return questions.some((question) => question.status === 'active');
  }, [questions]);

  const isHaveOnlyOnePublicQuestion = useMemo(() => {
    return (
      statusForm === 'public' &&
      questions.filter((question) => question.status === 'active').length === 1
    );
  }, [questions, statusForm]);

  const { videoInstruction, videoLearning } = useMemo(() => {
    const instruction = video?.videos?.find(
      (video: any) => video.type === VIDEO_TYPE.INSTRUCTION,
    );
    const learning = video?.videos?.find(
      (video: any) => video.type === VIDEO_TYPE.LEARNING,
    );

    return {
      videoInstruction: instruction || {},
      videoLearning: learning || {},
    };
  }, []);

  const mapToResults = (
    options: any[],
    dataResult: any[] | any,
    type: string,
  ) => {
    let results: { name: string; is_correct: boolean }[] = [];

    if (type === 'radio') {
      results = options.map((item) => ({
        name: item.value,
        is_correct: item.char === dataResult?.label,
      }));
    } else if (type === 'checkbox') {
      const checkBoxValues = dataResult.map((item: any) => item?.label);

      results = options.map((item) => ({
        name: item.value,
        is_correct: checkBoxValues.includes(item.char),
      }));
    }

    return results;
  };

  const questionsParam = (listQuestion: any[]) => {
    if (listQuestion.length) {
      return listQuestion.map((item) => {
        return {
          name: item.question,
          status: item.status,
          type: item.type.value,
          correct_note: item.note_for_correct,
          options: mapToResults(item.options, item.result, item.type.value),
        };
      });
    } else {
      return [];
    }
  };

  const onSubmit = async () => {
    const {
      pdfName1,
      pdfName2,
      pdfName3,
      pdfFile1,
      pdfFile2,
      pdfFile3,
      pdfFileName1,
      pdfFileSize1,
      pdfFileUrl1,
      pdfFileName2,
      pdfFileSize2,
      pdfFileUrl2,
      pdfFileName3,
      pdfFileSize3,
      pdfFileUrl3,
      thumbnail_id,
      video_id,
      video_url,
      video_guide_id,
      video_guide_url,
      thumbnail_guide_id,
      ...rest
    } = getValues();

    if (rest.status === 'public' && !isHavePublicQuestion) {
      toast?.current?.show(
        toastErrorMessage(t('lesson_video.validate.have_public_question')),
      );
      return;
    }

    const attachments = [
      {
        name: pdfName1 ?? undefined,
        file_name: pdfFileName1 ?? undefined,
        file_size: pdfFileSize1 ?? undefined,
        attach_file_id: pdfFile1 ?? undefined,
        file_url: pdfFileUrl1 ?? undefined,
      },
      {
        name: pdfName2 ?? undefined,
        file_name: pdfFileName2 ?? undefined,
        file_size: pdfFileSize2 ?? undefined,
        attach_file_id: pdfFile2 ?? undefined,
        file_url: pdfFileUrl2 ?? undefined,
      },
      {
        name: pdfName3 ?? undefined,
        file_name: pdfFileName3 ?? undefined,
        file_size: pdfFileSize3 ?? undefined,
        attach_file_id: pdfFile3 ?? undefined,
        file_url: pdfFileUrl3 ?? undefined,
      },
    ];

    const filteredAttachments = attachments.filter(
      (attachment) => attachment.name !== undefined,
    );

    let formatVideos = [
      {
        video_id: video_id,
        thumbnail_id: thumbnail_id,
        thumbnail_path: imageFile?.objectURL || videoLearning.thumbnail_path,
        video_path: video_url,
        size: imageFile?.size || videoLearning.size,
        type: VIDEO_TYPE.LEARNING,
        duration: parseInt(imageFile?.video_duration) || videoLearning.duration,
      },
      {
        video_id: video_guide_id,
        thumbnail_id: thumbnail_guide_id,
        thumbnail_path:
          imageFileGuide?.objectURL || videoInstruction.thumbnail_path,
        video_path: video_guide_url,
        size: imageFileGuide?.size || videoInstruction.size,
        type: VIDEO_TYPE.INSTRUCTION,
        duration:
          parseInt(imageFileGuide?.video_duration) || videoInstruction.duration,
      },
    ];

    if (lessonId) {
      formatVideos = formatVideos.filter((item) =>
        item.type === VIDEO_TYPE.LEARNING
          ? item.video_id !== videoLearning.video_id
          : item.video_id !== videoInstruction.video_id,
      );
    }

    onSubmitVideo({
      ...rest,
      attachments: filteredAttachments,
      create_by: currentUser.full_name_kanji,
      create_date: dayjs(new Date()).format('YYYY/MM/DD'),
      questionsParam: questionsParam(questions),
      questions,
      id: id || `${randomUUID()}_${ADD_VIDEO_TEMPORARY}`,
      ...(formatVideos.length ? { videos: formatVideos } : {}),
    });
    reset();
  };

  const getAllFieldNames = () => {
    return [
      'status',
      'name',
      'description',
      'attachments',
      'videos',
      'pdfFileName1',
      'pdfFileName2',
      'pdfFileName3',
      'pdfFileSize1',
      'pdfFileSize2',
      'pdfFileSize3',
      'pdfFileUrl1',
      'pdfFileUrl2',
      'pdfFileUrl3',
    ];
  };

  function getDetail() {
    if (video) {
      setDefaultValue(video);
      setAttachments(video.attachments);
    }
  }

  function setDefaultValue(data: any) {
    const specialField = ['videos'];
    getAllFieldNames().forEach((field: any) => {
      const val = data[field];

      if (val && !specialField.includes(field)) {
        setValue(field, val);
      }

      if (field === 'videos') {
        setValue('video_id', videoLearning?.video_id);
        setValue('thumbnail_id', videoLearning?.thumbnail_id);
        setValue('video_url', videoLearning?.video_path);
        setValue('video_guide_id', videoInstruction?.video_id || null);
        setValue('thumbnail_guide_id', videoInstruction?.thumbnail_id || null);
        setValue('video_guide_url', videoInstruction?.video_path || null);
      }
    });
  }

  function setAttachments(attachments?: any[]) {
    if (!attachments || !attachments.length) {
      Array.from(Array(3)).forEach((field: any, index: number) => {
        const newIndex = index + 1;
        setValue(`pdfName${newIndex}`, undefined);
        setValue(`pdfFile${newIndex}`, undefined);
        setValue(`pdfFileName${newIndex}`, undefined);
        setValue(`pdfFileSize${newIndex}`, undefined);
        setValue(`pdfFileUrl${newIndex}`, undefined);
      });
      return;
    }

    while (attachments.length < 3) {
      attachments.push({
        name: undefined,
        attach_file_id: undefined,
        id: undefined,
        file_name: undefined,
        original_name: undefined,
        file_size: undefined,
      });
    }

    attachments.forEach((field: any, index: number) => {
      const newIndex = index + 1;
      setValue(`pdfName${newIndex}`, field.name);
      setValue(`pdfFile${newIndex}`, field.attach_file_id || field.id);
      setValue(
        `pdfFileName${newIndex}`,
        field.file_name || field.original_name,
      );
      setValue(`pdfFileSize${newIndex}`, field.file_size);
      setValue(`pdfFileUrl${newIndex}`, field.file_url);
    });
  }

  const stateSetters = {
    video_guide_id: setWorkUploading,
    video_id: setVideoUploading,
    pdfFile1: setPdf1Uploading,
    pdfFile2: setPdf2Uploading,
    pdfFile3: setPdf3Uploading,
  };

  const handleSetUploading = (name: string, value: boolean) => {
    const setState = stateSetters[name as keyof typeof stateSetters];
    if (setState) {
      setState(value);
    }
  };

  const isDisabledWhenUpload = useMemo(() => {
    return (
      pdf1Uploading ||
      pdf2Uploading ||
      pdf3Uploading ||
      videoUploading ||
      workUploading
    );
  }, [
    videoUploading,
    pdf1Uploading,
    pdf2Uploading,
    pdf3Uploading,
    workUploading,
  ]);

  const onHideQuestion = () => {
    setVisibleQuestion(false);
  };

  const onSubmitQuestion = (newQuestions: any) => {
    const indexOldQuestion = questions.findIndex((question) => {
      return question.id === newQuestions.id;
    });
    if (indexOldQuestion === -1) {
      setQuestions(
        [...questions, newQuestions].map((item, index) => ({
          ...item,
          order_number: index + 1,
        })),
      );
      onHideQuestion();
      return;
    }
    questions[indexOldQuestion] = newQuestions;
    setQuestions(
      [...questions].map((item, index) => ({
        ...item,
        order_number: index + 1,
      })),
    );
    onHideQuestion();
  };

  const onEditQuestion = (rowData: any) => {
    setQuestionId(rowData.id);
    setQuestion(rowData);
    setVisibleQuestion(true);
  };

  const onNewQuestion = () => {
    setQuestionId('');
    setQuestion(undefined);
    setVisibleQuestion(true);
  };

  const deleteQuestionFromAddLessonForm = (rowData: any) => {
    const indexDelete = questions.findIndex(
      (question) => question.id === rowData.id,
    );
    if (indexDelete === -1) return;
    const updatedQuestions = [...questions];
    updatedQuestions.splice(indexDelete, 1);
    setQuestions(updatedQuestions);
    toast?.current?.show(toastMessage(t('common.toast.success')));
  };

  const deleteQuestionFromEditLessonForm = async (rowData: any) => {
    const { error: questionError } = await apiService.lessonQuestion.delete(
      rowData.id,
    );
    if (questionError) {
      const { error_code } = questionError
      toast?.current?.show(
        toastErrorMessage(
          t(
            error_code !== NO_PUBLIC_QUESTION
              ? 'common.toast.fail'
              : 'lesson_video.validate.delete_draft_question',
          ),
        ),
      );
      return;
    }
    const { data } = await apiService.lessonQuestion.index({
      unit_id: id || 0,
    });
    const newQuestions = handleFormatQuestion(data?.data as any, t);
    setQuestions(newQuestions);
    toast?.current?.show(toastMessage(t('common.toast.success')));
  };

  const onDeleteQuestion = (rowData: any) => {
    const hasNewQuestion = String(rowData.id).includes(ADD_QUESTION_TEMPORARY);
    setSelectedQuestion({ rowData });
    const nextDialogVisibleValue = !dialogVisible;
    setDialogVisible(nextDialogVisibleValue);
    if (nextDialogVisibleValue) return;
    setLoadingGlobal(true);
    id && !hasNewQuestion
      ? deleteQuestionFromEditLessonForm(rowData)
      : deleteQuestionFromAddLessonForm(rowData);
    setLoadingGlobal(false);
  };

  const tabTemplate = (options: TabPanelHeaderTemplateOptions) => {
    return (
      <div
        className="flex align-items-center cursor-pointer"
        onClick={options.onClick}
      >
        <span className="tab-label font-medium white-space-nowrap">
          {options.titleElement}
        </span>
      </div>
    );
  };

  const onRowReorder = async (e: DataTableRowReorderEvent<any>) => {
    if (!lessonId) {
      setQuestions(e.value);
      return;
    }

    setLoadingGlobal(true);
    const newQuestionsData = updatePrioritiesWhenDragging(questions, e);
    setQuestions(newQuestionsData);

    await apiService.lessonQuestion.collection(`priorities`, {
      method: 'POST',
      body: {
        unit_id: id,
        questions: newQuestionsData.map((question: any) => ({
          question_id: question.id,
          priority: question.priority,
        })),
      },
    });

    setLoadingGlobal(false);
  };

  useEffect(() => {
    if (!id) return;

    getDetail();
  }, [id, video]);

  useEffect(() => {
    setValue('is_new_video', String(id).includes(ADD_VIDEO_TEMPORARY) || !id);
  }, [id, setValue]);

  useEffect(() => {
    if (!visible) {
      setImageFile(undefined);
      setImageFileGuide(undefined);
    } else {
      if (!video) {
        reset();
        setValue('is_new_video', true);
        return;
      }

      if (video?.questions) {
        setQuestions(video?.questions);
      }
    }
  }, [visible, video?.questions]);

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-[708px] bg-white mt-[20px]"
      >
        <div className="flex flex-col gap-3">
          <div className="form-item flex">
            <div className="label flex-[0_0_196px] flex">
              <span className="text-gray-700">{t('lesson_video.status')}</span>
              <span className="text-error-500">*</span>
            </div>
            <div className="w-full">
              <RadioButtonCustomForm
                name="status"
                options={statusOptions}
                wrapClass="w-full"
                radioGroupClassName="flex gap-4 w-full"
                radioItemClassName="radio-input text-gray-700 text-[14px] font-normal flex-1 h-[44px]"
                onChange={
                  lessonId
                    ? (data: any) => {
                      setIsChangeStatus(data?.value === 'draft' && data?.value !== video.status);
                    }
                    : undefined
                }
              />
              {isChangeStatus && (
                <div className="whitespace-pre-wrap text-[12px] flex items-center mt-[4px]">
                  <i className="pi pi-exclamation-triangle mr-[8px] ml-[4px]" />{' '}
                  {t('lesson_video.warning_change_status')}
                </div>
              )}
            </div>
          </div>

          <div className="form-item flex">
            <div className="label flex-[0_0_196px] flex">
              <span className="text-gray-700">
                {t('lesson_video.video.title')}
              </span>
              <span className="text-error-500">*</span>
            </div>
            <InputTextCustomForm
              name="name"
              wrapClass="w-full"
              className="h-[44px] text-[14px]"
              placeholder={t('lesson_video.video.placeholder')}
            />
          </div>

          <div className="form-item flex">
            <div className="label flex-[0_0_196px] flex">
              <span className="text-gray-700">
                {t('lesson_video.description.title')}
              </span>
              <span className="text-error-500">*</span>
            </div>
            <InputTextCustomForm
              name="description"
              wrapClass="w-full"
              className="h-[44px] text-[14px]"
              placeholder={t('lesson_video.description.placeholder')}
              maxLength={255}
            />
          </div>

          <div className="form-item flex">
            <div className="label flex-[0_0_196px] flex">
              <span className="text-gray-700">
                {t('lesson_video.upload.title')}
              </span>
              <span className="text-error-500">*</span>
            </div>
            <UploadVideo
              idChooseBtn="choose-video"
              className="relative flex-full-center border border-gray-200 w-full"
              acceptedTypes={['video/mp4']}
              name="video_id"
              textUpload={t('lesson.text_upload')}
              textSubUpload={t('lesson.text_sub_upload')}
              defaultUrl={
                videoLearning.thumbnail_path || video?.thumbnail_url || ''
              }
              handleUploaded={({
                video_id,
                thumbnail_id,
                currentFile,
                video_url,
              }) => {
                setValue('video_id', video_id);
                setValue('thumbnail_id', thumbnail_id);
                setValue('video_url', video_url);
                setImageFile(currentFile);
                setError('video_id', { message: '' });
              }}
              errors={errors}
              handleSetUploading={handleSetUploading}
            />
          </div>

          <div className="form-item flex">
            <div className="label flex-[0_0_196px] flex">
              <span className="text-gray-700">
                {t('lesson_video.upload_guide.title')}
              </span>
              <span className="text-error-500">*</span>
            </div>
            <UploadVideo
              idChooseBtn="choose-video-guide"
              className="relative flex-full-center border border-gray-200 w-full"
              acceptedTypes={['video/mp4']}
              name="video_guide_id"
              textUpload={t('lesson.text_upload')}
              textSubUpload={t('lesson.text_sub_upload')}
              defaultUrl={
                videoInstruction.thumbnail_path || video?.thumbnail_url || ''
              }
              handleUploaded={({
                video_id,
                thumbnail_id,
                currentFile,
                video_url,
              }) => {
                setValue('video_guide_id', video_id);
                setValue('thumbnail_guide_id', thumbnail_id);
                setValue('video_guide_url', video_url);
                setImageFileGuide(currentFile);
                setError('video_guide_id', { message: '' });
              }}
              errors={errors}
              handleSetUploading={handleSetUploading}
            />
          </div>

          <div className="bg-gray-100 p-[16px] rounded-[8px] flex flex-col gap-3">
            <div className="form-item flex">
              <div className="label flex-[0_0_196px] flex">
                <span className="text-gray-700">
                  {t('lesson_video.pdf1.title')}
                </span>
              </div>
              <InputTextCustomForm
                name="pdfName1"
                wrapClass="w-full"
                className="h-[44px] text-[14px]"
                placeholder={t('lesson_video.pdf1.placeholder')}
                maxLength={200}
              />
            </div>

            <div className="form-item flex">
              <div className="label flex-[0_0_196px] flex">
                <span className="text-gray-700">
                  {t('lesson_video.pdf1.upload')}
                </span>
              </div>
              <UploadFile
                idChooseBtn="choose-pdf-1"
                className="relative border border-gray-300 overflow-hidden"
                accept="application/pdf"
                acceptedTypes={['application/pdf']}
                name="pdfFile1"
                defaultFileName={watch('pdfFileName1') || ''}
                defaultFileSize={watch('pdfFileSize1') || 0}
                handleUploaded={(id, fileName, fileSize, fileUrl) => {
                  setValue('pdfFile1', id);
                  setValue('pdfFileName1', fileName);
                  setValue('pdfFileSize1', fileSize);
                  setValue('pdfFileUrl1', fileUrl);
                  setError('pdfFile1', { message: '' });
                }}
                handleSetUploading={handleSetUploading}
                errors={errors}
              />
            </div>
          </div>

          <div className="bg-gray-100 p-[16px] rounded-[8px] flex flex-col gap-3">
            <div className="form-item flex">
              <div className="label flex-[0_0_196px] flex">
                <span className="text-gray-700">
                  {t('lesson_video.pdf2.title')}
                </span>
              </div>
              <InputTextCustomForm
                name="pdfName2"
                wrapClass="w-full"
                className="h-[44px] text-[14px]"
                placeholder={t('lesson_video.pdf2.placeholder')}
                maxLength={200}
              />
            </div>

            <div className="form-item flex">
              <div className="label flex-[0_0_196px] flex">
                <span className="text-gray-700">
                  {t('lesson_video.pdf2.upload')}
                </span>
              </div>
              <UploadFile
                idChooseBtn="choose-pdf-2"
                className="relative border border-gray-300 overflow-hidden"
                accept="application/pdf"
                acceptedTypes={['application/pdf']}
                name="pdfFile2"
                defaultFileName={watch('pdfFileName2') || ''}
                defaultFileSize={watch('pdfFileSize2') || 0}
                handleUploaded={(id, fileName, fileSize, fileUrl) => {
                  setValue('pdfFile2', id);
                  setValue('pdfFileName2', fileName);
                  setValue('pdfFileSize2', fileSize);
                  setValue('pdfFileUrl2', fileUrl);
                  setError('pdfFile2', { message: '' });
                }}
                handleSetUploading={handleSetUploading}
                errors={errors}
              />
            </div>
          </div>

          <div className="bg-gray-100 p-[16px] rounded-[8px] flex flex-col gap-3">
            <div className="form-item flex">
              <div className="label flex-[0_0_196px] flex">
                <span className="text-gray-700">
                  {t('lesson_video.pdf3.title')}
                </span>
              </div>
              <InputTextCustomForm
                name="pdfName3"
                wrapClass="w-full"
                className="h-[44px] text-[14px]"
                placeholder={t('lesson_video.pdf3.placeholder')}
                maxLength={200}
              />
            </div>

            <div className="form-item flex">
              <div className="label flex-[0_0_196px] flex">
                <span className="text-gray-700">
                  {t('lesson_video.pdf3.upload')}
                </span>
              </div>
              <UploadFile
                idChooseBtn="choose-pdf-3"
                className="relative border border-gray-300 overflow-hidden"
                accept="application/pdf"
                acceptedTypes={['application/pdf']}
                name="pdfFile3"
                defaultFileName={watch('pdfFileName3') || ''}
                defaultFileSize={watch('pdfFileSize3') || 0}
                handleUploaded={(id, fileName, fileSize, fileUrl) => {
                  setValue('pdfFile3', id);
                  setValue('pdfFileName3', fileName);
                  setValue('pdfFileSize3', fileSize);
                  setValue('pdfFileUrl3', fileUrl);
                  setError('pdfFile3', { message: '' });
                }}
                handleSetUploading={handleSetUploading}
                errors={errors}
              />
            </div>
          </div>
        </div>
      </form>
      <TabView activeIndex={0} className="questions-tab-nav">
        <TabPanel header="テスト" headerTemplate={tabTemplate}>
          <CustomDataTable
            wrapClassName="mt-[20px]"
            values={questions}
            colums={LessonQuestionColumns(
              t,
              onEditQuestion,
              onDeleteQuestion,
              isHaveOnlyOnePublicQuestion,
            )}
            reorderableRows
            onRowReorder={onRowReorder}
            extraFooter={
              questions.length < MAX_QUESTION_DISPLAY && (
                <div className="px-[24px] py-[16px]">
                  <div>
                    <button
                      type="button"
                      className="flex gap-3 items-center"
                      onClick={() => {
                        onNewQuestion();
                      }}
                    >
                      <div className="bg-primary-100 p-[10px] h-[40px] w-[40px] flex-full-center text-primary-700 rounded-full">
                        <i className="pi pi-plus !font-bold"></i>
                      </div>
                      <div className="text-primary-700">
                        {t('lesson_question.add')}
                      </div>
                    </button>
                  </div>
                </div>
              )
            }
          />
        </TabPanel>
      </TabView>

      <div>{t('lesson_video.alert_question')}</div>

      <AddQuestionForm
        visible={visibleQuestion}
        onHide={onHideQuestion}
        onSubmitQuestion={onSubmitQuestion}
        id={questionId}
        question={question}
        mapToResults={mapToResults}
        unitId={id}
        setQuestions={setQuestions}
        isDisableDraftBtn={isHaveOnlyOnePublicQuestion}
      />

      <DialogCustom
        header={t('lesson.confirm.delete_question_header')}
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
        footer={
          <div className="field">
            <div className="flex justify-end">
              <ButtonCustom
                type="button"
                className="cancle-button p-button-sm mr-2"
                severity="secondary"
                onClick={() => setDialogVisible(false)}
              >
                {t('common.dialog.cancel')}
              </ButtonCustom>
              <ButtonCustom
                type="button"
                onClick={() => onDeleteQuestion(selectedQuestion.rowData)}
              >
                {t('common.dialog.ok')}
              </ButtonCustom>
            </div>
          </div>
        }
      >
        {t('lesson.confirm.delete_question_body')}
      </DialogCustom>

      <div className="pt-[20px] mt-[20px] mb-[10px] border-t border-gray-200">
        <div className="flex-full-center gap-[20px] w-[512px] ml-auto">
          <ButtonCustom
            type="button"
            severity="secondary"
            className="flex-full-center w-full font-medium text-[16px]"
            onClick={() => onHide((id || '') as string, questions)}
          >
            {t('lesson_video.button.cancel')}
          </ButtonCustom>
          <ButtonCustom
            type="button"
            onClick={handleSubmit(onSubmit)}
            loading={false}
            className="flex-full-center w-full font-medium text-[16px]"
            disabled={isDisabledWhenUpload}
          >
            {t('lesson_video.button.add')}
          </ButtonCustom>
        </div>
      </div>
    </FormProvider>
  );
};

export default AddVideoForm;
