import React from 'react';
import LessonTopHeader from './components/LessonTopHeader';
import LessonFormSearch from './components/LessonFormSearch';
import LessonList from './components/LessonList';

const LessonPage: React.FC = () => {

  return (
    <>
      <LessonTopHeader />
      <LessonFormSearch />
      <LessonList />
    </>
  )
}

export default LessonPage;