import React from 'react';
import { useNavigate } from 'react-router-dom';

import ButtonCustom from '@/components/common/ui/button';
import { useTranslation } from '@/config/i18n';
import PATH from '@/routes/path';
import useUserRole from '@/hooks/useUserRole';
import { ROLE } from '@/shared/constants/user';

const LessonTopHeader: React.FC = () => {
  const [t] = useTranslation('');
  const navigate = useNavigate();
  const { role: userRole } = useUserRole();

  function handleNavigateNew() {
    const pathNew = `${PATH.lesson}/${PATH.lesson_new}`;
    navigate(pathNew);
  }

  return (
    <>
      <div className="header-top flex items-center justify-between">
        <h2 className="title">{t('lesson_list.title')}</h2>
        <div className="action flex items-center">
          {[ROLE.ADMIN, ROLE.SUPPER_ADMIN].includes(userRole) && (
            <ButtonCustom className="ml-5" onClick={() => handleNavigateNew()}>
              <span className="flex relative items-center">
                <span className="pi pi-plus mr-2" />
                <span>{t('lesson_list.new')}</span>
              </span>
            </ButtonCustom>
          )}
        </div>
      </div>
    </>
  );
};

export default LessonTopHeader;
