import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { InputSwitch } from 'primereact/inputswitch';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from '@/config/i18n';
import { apiRequest } from '@/services';
import { UserFormType, userFormSchema } from '@/shared/schema/user';
import { InputTextCustomForm } from '@/components/common/ui/input/Input';
import ButtonCustom from '@/components/common/ui/button';
import { CalendarCustomForm } from '@/components/common/ui/calendar';
import { TextareaCustomForm } from '@/components/common/ui/textarea';
import UploadImage from '@/components/common/ui/upload/uploadImage';
import { useSelectorUserInfo } from '@/components/auth/AuthContext';
import {
  getValueFromSelect,
  toastErrorMessage,
  toastMessage,
} from '@/lib/utils';
import usePathName from '@/hooks/usePathName';
import { ROLE } from '@/shared/constants/user';
import PATH from '@/routes/path';
import { HTTP_STATUS, MAX_LENGTH } from '@/shared/constants';
import { useLoading } from '@/components/common/loader/LoadingContext';
import { ToastContext } from '@/components/common/CommonToast';
import useScrollToError from '@/hooks/useScrollToError';
import InfinitySelect from '../common/ui/select';

type Props = {
  id?: number;
};

let controllerMentor = new AbortController();

const UserForm: React.FC<Props> = ({ id }) => {
  const { toast } = useContext(ToastContext);
  const [t] = useTranslation('');
  const { apiService } = apiRequest();
  const [isActive, setIsActive] = useState(true);
  const [defaultImage, setDefaultImage] = useState();
  const pathName = usePathName();
  const navigate = useNavigate();
  const { scrollToError } = useScrollToError();
  const { loadingGlobal, setLoadingGlobal } = useLoading();
  const { currentUser } = useSelectorUserInfo();
  const userRole = currentUser?.current_roles?.[0] || '';

  const role = pathName.includes(PATH.student_management)
    ? ROLE.STUDENT
    : ROLE.TEACHER;

  const formMethods = useForm<UserFormType>({
    mode: 'onChange',
    resolver: yupResolver(userFormSchema),
    shouldFocusError: false,
    defaultValues: {
      require_password_update: true,
      is_student: role === ROLE.STUDENT ? true : false,
      status: true,
      email: '',
      password: '',
      password_confirmation: '',
      first_name_kanji: '',
      last_name_kanji: '',
      first_name: '',
      last_name: '',
      date_of_birth: '',
      gender: '',
      phone_number: '',
      post_code: '',
      address: '',
    },
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = formMethods;

  const getAllFieldNames = () => {
    const values = getValues();
    return Object.keys(values);
  };

  const graduationOptions = useMemo(() => {
    return [
      ...Array.from({ length: 999 }, (_, i) => ({
        value: i + 1,
        label: `${i + 1}`,
      })),
    ];
  }, []);

  async function getUserDetail(id: number) {
    setLoadingGlobal(true);
    const { data: user } = await apiService.users.show(id);

    if (user?.data) {
      setDefaultValue(user.data);
      setDefaultImage(user.data.profile_image);
    }
    setLoadingGlobal(false);
  }

  function setDefaultValue(data: any) {
    const specialField = [
      'mentor_id',
      'gender',
      'date_of_birth',
      'status',
      'require_password_update',
      'graduation_terms',
    ];
    getAllFieldNames().forEach((field: any) => {
      const val = data[field];

      if (val && !specialField.includes(field)) {
        setValue(field, val);
      }

      if (field === 'mentor_id') {
        setValue(field, {
          label: data.mentor?.full_name_kanji,
          value: String(data.mentor?.id),
          subLabel: data.mentor?.email,
        });
      }

      if (field === 'graduation_terms') {
        setValue(field, {
          label: val,
          value: val,
        });
      }

      if (field === 'gender') {
        setValue(field, { label: t(`common.gender.${val}`), value: val });
      }

      if (field === 'status') {
        const status = data.status === 'active' ? true : false;
        setValue(field, status);
        setIsActive(status);
      }

      if (field === 'date_of_birth') {
        if (val) {
          setValue(field, new Date(val));
        }
      }

      if (field === 'require_password_update') {
        setValue(field, false);
      }
    });
  }

  useEffect(() => {
    if (!id) {
      setValue('gender', {
        label: t(`common.gender.male`),
        value: 'male',
      } as unknown as any);

      if (!isEmpty(currentUser) && userRole === ROLE.TEACHER) {
        setValue('mentor_id', {
          label: currentUser.full_name_kanji,
          value: String(currentUser.id),
          subLabel: currentUser.email,
        } as any);
        return;
      }
      return;
    }

    getUserDetail(id);
  }, [id]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      scrollToError();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [errors]);

  async function onSubmit() {
    setLoadingGlobal(true);
    const payload = getPayload();
    let data = null;
    let error = null;
    if (id) {
      const { data: user, error: userError } = await apiService.users.update(
        id,
        payload,
      );
      data = user;
      error = userError;
    } else {
      const { data: user, error: userError } =
        await apiService.users.create(payload);
      data = user;
      error = userError;
    }
    if (error) {
      handleError(error);
      setLoadingGlobal(false);
      return;
    }

    if (data) {
      let msg =
        role === ROLE.STUDENT
          ? t('user.student_success')
          : t('user.coach_success');
      if (id) {
        msg =
          role === ROLE.STUDENT
            ? t('user.update_student_success')
            : t('user.update_coach_success');
      }
      toast?.current?.show(toastMessage(msg));
      setLoadingGlobal(false);
      if (role === ROLE.TEACHER) {
        navigate(PATH.coach_management);
        return;
      }
      navigate(PATH.student_management);
    }
  }

  function getPayload() {
    const status = isActive ? 'active' : 'inactive';
    const { date_of_birth, password, password_confirmation, ...rest } =
      getValues();
    const valuesSelect = getValueFromSelect(rest, [
      'mentor_id',
      'gender',
      'graduation_terms',
    ]);
    const dateOfBirth = date_of_birth
      ? dayjs(date_of_birth.toString()).format('YYYY-MM-DD')
      : '';
    const passwordCustom = !password ? undefined : password;
    const passwordConfirmationCustom = !password_confirmation
      ? undefined
      : password_confirmation;

    return {
      ...rest,
      ...valuesSelect,
      role,
      status,
      date_of_birth: dateOfBirth,
      password: passwordCustom,
      password_confirmation: passwordConfirmationCustom,
    };
  }

  function handleError(error: any) {
    const { status_code, errors } = error;
    if (status_code === HTTP_STATUS.FORM_ERROR) {
      errors.forEach((e: any) => {
        const { field, message } = e;
        setError(field, { message });
      });
      return;
    }

    const msg =
      role === ROLE.STUDENT ? t('user.student_fail') : t('user.coach_fail');
    toast?.current?.show(toastErrorMessage(msg));
  }

  const fetchMentorOptions = useCallback(async (params: any) => {
    if (controllerMentor.abort) controllerMentor.abort();
    controllerMentor = new AbortController();
    const { signal } = controllerMentor;
    return await apiService.users.index(
      {
        ...params,
        status: 'active',
        role: 'TEACHER',
      },
      { signal },
    );
  }, []);

  const onBeforeUploadAvatar = (event: any) => {
    const MAX_SIZE_MB = 20;
    const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;

    const file = event.files[0];
    if (file.size > MAX_SIZE_BYTES) {
      event.options.clear(); // clear the files from the upload
      toast?.current?.show(toastErrorMessage(t('common.file.image_max_20MB')));
      return;
    }
  };

  function handleNavigateBack() {
    let pathBack = PATH.student_management;
    if (role === ROLE.TEACHER) {
      pathBack = PATH.coach_management;
    }

    navigate(pathBack);
  }

  return (
    <>
      <FormProvider {...formMethods}>
        <form
          className="mt-[16px]"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <div className="form-item flex">
            <div className="label w-56 flex">
              <span>{t('user.status')}</span>
            </div>
            <InputSwitch
              name="status"
              checked={isActive}
              onChange={(e) => setIsActive(e.value)}
            />
            <span className="ml-2">
              {isActive ? t('user.active') : t('user.in_active')}
            </span>
          </div>

          <div className="form-item flex mt-[20px]">
            <div className="label w-56 flex">
              <span>{t('user.avatar')}</span>
            </div>
            <div className="w-[512px] flex">
              <UploadImage
                idChooseBtn="choose-image"
                className="relative w-[366px] h-[126px] flex-full-center border border-gray-200"
                name="profile_image_id"
                accept=".svg,.jpg,.jpeg,.png"
                defaultUrl={defaultImage}
                onBeforeUpload={onBeforeUploadAvatar}
                maxSize={20}
                handleUploaded={(id) => {
                  setValue('profile_image_id', id);
                }}
                textUpload="ファイルを選択"
                textSubUpload="またはここにファイルをドロップ"
              />
            </div>
          </div>

          <div className="form-item flex mt-[20px]">
            <div className="label w-56 flex">
              <span>{t('user.email')}</span>
              <span className="text-error-500">*</span>
            </div>
            <InputTextCustomForm
              name="email"
              wrapClass="w-[512px]"
              disabled={!!id}
            />
          </div>

          <div className="form-item flex mt-[20px]">
            <div className="label w-56 flex">
              <span>{t('user.phone_number')}</span>
              <span className="text-error-500">*</span>
            </div>
            <div className="w-[512px]">
              <InputTextCustomForm
                name="phone_number"
                wrapClass="w-full"
                maxLength={MAX_LENGTH.PHONE_NUMBER}
                isNumber
              />
              <p className="text-[#757575] text-[12px] mt-2 whitespace-pre-line">
                {t('user.phone_hint')}
              </p>
            </div>
          </div>

          <div className="form-item flex mt-[20px]">
            <div className="label w-56 flex">
              <span>{t('user.password')}</span>
              {!id && <span className="text-error-500">*</span>}
            </div>
            <div className="w-[512px]">
              <div className="grid grid-cols-2 gap-6">
                <InputTextCustomForm
                  name="password"
                  type="text"
                  isHideValue={true}
                  placeholder={t('user.password')}
                  maxLength={MAX_LENGTH.PASSWORD}
                />
                <InputTextCustomForm
                  name="password_confirmation"
                  type="text"
                  isHideValue={true}
                  placeholder={t('user.password_confirm')}
                  maxLength={MAX_LENGTH.PASSWORD}
                />
              </div>
              <p className="text-[#757575] text-[12px] mt-2 whitespace-pre-line">
                {t('user.password_hint')}
              </p>
            </div>
          </div>

          {role === ROLE.STUDENT && (
            <div className="form-item flex mt-[20px]">
              <div className="label w-56 flex">
                <span>{t('user.mentor')}</span>
                <span className="text-error-500">*</span>
              </div>
              <InfinitySelect
                placeholder=""
                wrapClass="w-[512px]"
                name="mentor_id"
                service={[ROLE.ADMIN, ROLE.SUPPER_ADMIN].includes(userRole) && fetchMentorOptions}
                fieldLabels={{
                  label: 'full_name_kanji',
                  value: 'id',
                  subLabel: 'email',
                }}
                filterOption={() => true}
                isDisabled={userRole === ROLE.TEACHER}
              />
            </div>
          )}

          <div className="break-line border-t border-gray-200 h-[1px] mt-[20px]"></div>

          <div className="form-item flex mt-[20px]">
            <div className="label w-56 flex">
              <span>{t('user.kanji_name')}</span>
              <span className="text-error-500">*</span>
            </div>
            <div className="w-[512px] grid grid-cols-2 gap-6">
              <InputTextCustomForm
                name="last_name_kanji"
                placeholder={t('user.last_name_kanji_placeholder')}
              />
              <InputTextCustomForm
                name="first_name_kanji"
                placeholder={t('user.first_name_kanji_placeholder')}
              />
            </div>
          </div>

          <div className="form-item flex mt-[20px]">
            <div className="label w-56 flex">
              <span>{t('user.name')}</span>
              <span className="text-error-500">*</span>
            </div>
            <div className="w-[512px] grid grid-cols-2 gap-6">
              <InputTextCustomForm
                name="last_name"
                placeholder={t('user.last_name_placeholder')}
              />
              <InputTextCustomForm
                name="first_name"
                placeholder={t('user.first_name_placeholder')}
              />
            </div>
          </div>

          {role === ROLE.TEACHER && (
            <div className="form-item flex mt-[20px]">
              <div className="label w-56 flex">
                <span>{t('user.graduation_terms')}</span>
                <span className="text-error-500">*</span>
              </div>
              <InfinitySelect
                placeholder=""
                wrapClass="w-[512px]"
                name="graduation_terms"
                defaultOption={graduationOptions}
                focusDefaultOption={false}
                onMenuOpen={() => {
                  setTimeout(() => {
                    const selectedEl = document.getElementsByClassName(
                      'select__option--is-selected',
                    )[0];
                    if (selectedEl) {
                      selectedEl.scrollIntoView({});
                    }
                  }, 15);
                }}
              />
            </div>
          )}

          <div className="form-item flex mt-[20px]">
            <div className="label w-56 flex">
              <span>{t('user.date_of_birth')}</span>
            </div>
            <div className="w-[512px]">
              <CalendarCustomForm
                wrapClass="w-full"
                name="date_of_birth"
                dateFormat="yy/mm/dd"
                maxDate={new Date()}
                showIcon
                icon={() => <i className="pi pi-calendar text-[18px]" />}
                placeholder="yyyy/mm/dd"
                panelClassName="calendar-date-of-birth"
              />
            </div>
          </div>

          <div className="form-item flex mt-[20px]">
            <div className="label w-56 flex">
              <span>{t('user.gender')}</span>
            </div>
            <div className="w-[512px]">
              <InfinitySelect
                name="gender"
                placeholder=""
                wrapClass="w-[512px]"
                isClearable={false}
                defaultOption={[
                  { value: 'male', label: t('common.gender.male') },
                  { value: 'female', label: t('common.gender.female') },
                  { value: 'other', label: t('common.gender.other') },
                ]}
              />
            </div>
          </div>

          <div className="form-item flex mt-[20px]">
            <div className="label w-56 flex">
              <span>{t('user.post_code')}</span>
              <span className="text-error-500">*</span>
            </div>
            <div className="w-[512px]">
              <InputTextCustomForm
                wrapClass="w-full"
                name="post_code"
                placeholder="XXXXXXX"
                maxLength={MAX_LENGTH.POSTCODE}
              />
              <p className="text-[#757575] text-[12px] mt-2 whitespace-pre-line">
                {t('user.postcode_hint')}
              </p>
            </div>
          </div>

          <div className="form-item flex mt-[20px]">
            <div className="label w-56 flex">
              <span>{t('user.address')}</span>
              <span className="text-error-500">*</span>
            </div>
            <TextareaCustomForm name="address" wrapClass="w-[512px]" maxLength={MAX_LENGTH.ADDRESS} />
          </div>

          <div className="action flex justify-end mt-[25px] border-t border-gray-200 py-[18px]">
            <ButtonCustom
              className="w-[140px]"
              type="button"
              severity="secondary"
              onClick={() => handleNavigateBack()}
            >
              {t('common.form.cancel')}
            </ButtonCustom>
            <ButtonCustom
              className="ml-2 w-[140px]"
              type="submit"
              loading={loadingGlobal}
            >
              {id ? t('common.form.save') : t('common.form.new')}
            </ButtonCustom>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default UserForm;
