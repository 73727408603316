const lesson_video = {
  header: '動画追加',
  status: 'ステータス',
  video: {
    title: 'ユニットタイトル',
    title_validate: 'ユニットタイトル',
    placeholder: 'タイトル',
  },
  description:  {
    title: '概要',
    placeholder: '動画について説明文書を書いてください',
  },
  upload: {
    title: '視聴動画',
    required: '視聴動画をアップロードしてください。',
  },
  upload_guide: {
    title: 'ワーク案内動画',
    required: 'ワーク案内動画をアップロードしてください。',
  },
  pdf1: {
    title: 'ファイル名 1',
    placeholder: 'ファイル名',
    upload: 'PDF-1'
  },
  pdf2: {
    title: 'ファイル名 2',
    placeholder: 'ファイル名',
    upload: 'PDF-2'
  },
  pdf3: {
    title: 'ファイル名 3',
    placeholder: 'ファイル名',
    upload: 'PDF-3'
  },
  button: {
    cancel: 'キャンセル',
    add: '保存',
  },
  table: {
    priority: '順番',
    unit_name: 'ユニット名',
    video_learning: '視聴動画',
    video_instruction: 'ワーク案内動画',
    questions: 'テストの問数',
    image: 'イメージ',
    title: 'タイトル',
    create_by: '作成者',
    create_date: '作成日',
    status: 'ステータス',
    public: '公開',
    draft: '非公開',
    pdf: 'PDF',
  },
  validate: {
    have_public_question: 'ご作成のユニットを保存するためには最低でも1つの公開質問が必要です。',
    edit_draft_question: 'ご指定ユニットは公開ですため、上書き保存できません。',
    delete_draft_question: 'ご指定ユニットは公開ですため、削除できません。',
  },
  warning_change_status: '編集した非公開ステータスが未保存であれば、テスト内容を変更しても保存されません。\nテスト内容変更は保存したい場合、先に非公開ステータスを保存してください。',
  alert_question: '※非公開の質問は最後尾へ移動してください。',
};

export default lesson_video;
