import IconCoach from '@/components/icons/IconCoach';
import IconStudent from '@/components/icons/IconStudent';
import PATH from '@/routes/path';
import { MenuItem } from '@/types/menu';
import { ROLE } from './user';
import IconLesson from '@/components/icons/IconLesson';
import { IconSetting } from '@/components/icons';

export const MENU_ITEMS: MenuItem[] = [
  {
    title: 'menu.coach_management',
    link: PATH.coach_management,
    auth: true,
    icon: IconCoach,
    roles: [ROLE.SUPPER_ADMIN, ROLE.ADMIN],
  },
  {
    title: 'menu.student_management',
    link: PATH.student_management,
    auth: true,
    icon: IconStudent,
    roles: [ROLE.SUPPER_ADMIN, ROLE.ADMIN, ROLE.TEACHER],
  },
  {
    title: 'menu.lesson',
    link: PATH.lesson,
    auth: true,
    icon: IconLesson,
    roles: [ROLE.SUPPER_ADMIN, ROLE.ADMIN, ROLE.TEACHER],
  },
  {
    title: 'menu.setting',
    link: PATH.version_management,
    auth: true,
    icon: IconSetting,
    roles: [ROLE.SUPPER_ADMIN],
    children: [
      {
        title: 'menu.version_management',
        link: PATH.version_management,
        auth: true,
        roles: [ROLE.SUPPER_ADMIN],
      },
    ],
  },
];
