import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTableRowReorderEvent } from 'primereact/datatable';
import { isEmpty } from 'lodash';

import useSearch from '@/hooks/useSearch';
import { apiRequest } from '@/services';
import { CustomDataTable } from '@/components/common/ui/table/CustomDataTable';
import { Pagination } from '@/types/pagination';
import { useTranslation } from '@/config/i18n';
import usePagination from '@/hooks/usePagination';
import usePathName from '@/hooks/usePathName';
import PATH from '@/routes/path';
import { ROLE } from '@/shared/constants/user';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@/shared/constants/pagination';
import { useLoading } from '@/components/common/loader/LoadingContext';
import DialogCustom from '@/components/common/ui/dialog';
import ButtonCustom from '@/components/common/ui/button';
import { ToastContext } from '@/components/common/CommonToast';
import { toastErrorMessage, toastMessage } from '@/lib/utils';
import { LessonListColumns } from '@/pages/lesson/table/LessonListColumns';
import { LessonItemType } from '@/types/lesson';
import useUserRole from '@/hooks/useUserRole';
import { updatePrioritiesWhenDragging, validatePriorities } from './utils';
import { NO_PUBLIC_UNIT } from '@/shared/constants';

const LessonList: React.FC = () => {
  const [t] = useTranslation('');
  const { apiService } = apiRequest();
  const { searchParams } = useSearch();
  const { toast } = useContext(ToastContext);
  const { query } = Object.fromEntries(searchParams.entries());
  const navigate = useNavigate();
  const pathName = usePathName();
  const { setLoadingGlobal } = useLoading();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState<any>();
  const [isEditPriority, setIsEditPriority] = useState(false);
  const [lessons, setLessons] = useState<any>({data: [], pagination: null})
  const [currentPagination, setCurrentPagination] = useState<any>()
  const [lessonErrors, setLessonErrors] = useState<Record<number, string>>({});

  const { role: userRole } = useUserRole();

  const role = useMemo(() => {
    return pathName.includes(PATH.student_management)
      ? ROLE.STUDENT
      : ROLE.TEACHER;
  }, []);

  const isDisabledEdit = useMemo(() => [ROLE.TEACHER].includes(userRole), [userRole]);

  const params = useMemo(() => {
    return query
      ? undefined
      : { limit: DEFAULT_PER_PAGE, paginate: DEFAULT_PAGE, role };
  }, [query]);

  const queryCustom = useMemo(() => {
    return query ? `${query}&role=${role}` : '';
  }, [query, role]);

  const getLessons = async () => {
    setLoadingGlobal(true);
    const { data: res, error } = await apiService.lessons.query(
      queryCustom,
      params,
    );
    if (res) {
      setLoadingGlobal(false);
      window.scrollTo({ top: 0, left: 0 });
      setLessons({ data: res?.data, pagination: res?.pagination });
      setCurrentPagination(res?.pagination)
    }
    if (error) {
      setLoadingGlobal(false);
      toast?.current?.show(toastErrorMessage(t('common.toast.fail')));
    }
  };

  const { paginationComputed, setPagination } = usePagination(
    lessons?.pagination,
  );

  function handleEditLesson(id: number) {
    navigate(`${PATH.lesson}/${PATH.lesson_edit}/${id}`);
  }

  async function handleEditStatusLesson(
    isActive: boolean,
    rowData: LessonItemType,
  ) {
    setSelectedLesson({ isActive, rowData });
    const nextDialogVisibleValue = !dialogVisible;
    setDialogVisible(nextDialogVisibleValue);
    if (nextDialogVisibleValue) return;
    setLoadingGlobal(true);
    const { data: lessonData, error: lessonError } =
      await apiService.lessons.update(rowData.id, {
        status: isActive ? 'active' : 'inactive',
      });
    setLoadingGlobal(false);
    if (lessonError) {
      const errorPublicUnit = lessonError?.errors.find((item: any) => item.error_code === NO_PUBLIC_UNIT);
      if (!isEmpty(errorPublicUnit)) {
        toast?.current?.show(toastErrorMessage(errorPublicUnit.message));
        return;
      }
      return;
    }

    // update status button
    const indexUpdate = lessons.data.findIndex(
      (lesson: LessonItemType) => lesson.id === rowData.id,
    );
    if (indexUpdate !== -1) {
      lessons.data[indexUpdate].status = lessonData.data.status;
    }
  }

  function onPaginationChanged(paginationState: Pagination) {
    setPagination(paginationState);
  }

  const handleOpenProgress = (rowData: any) => {
    navigate(`${PATH.lesson}/${rowData.id}/progress`);
  };

  const onRowReorder = async (e: DataTableRowReorderEvent<any>) => {
    setLoadingGlobal(true);
    
    const newLessonData = updatePrioritiesWhenDragging(lessons?.data, e);
    setLessons({ data: newLessonData, pagination: currentPagination });

    const lessonsReq = newLessonData.map((lesson: any) => {
      return {
        lesson_id: lesson?.id,
        priority: lesson?.priority,
      };
    }).filter((item: any) => item.priority);

    await apiService.lessons.collection(`priorities`, {
      method: 'POST',
      body: {
        lessons: lessonsReq,
      },
    });

    setLoadingGlobal(false);
  }

  const onEditPriority = () => {
    setIsEditPriority(true);
  }

  const onSavePriority = async () => {
    if(!isEmpty(lessonErrors)) return;

    setLoadingGlobal(true);
    const lessonsReq = lessons.data?.map((lesson: any) => {
      return {
        lesson_id: lesson?.id,
        priority: lesson?.priority,
      };
    }).filter((item: any) => item.priority);
    
    const { error } = await apiService.lessons.collection(`priorities`, {
      method: 'POST',
      body: {
        lessons: lessonsReq,
      },
    });

    if (error) {
      setLoadingGlobal(false);
      setIsEditPriority(false);
      toast?.current?.show(
        toastErrorMessage(t('lesson.noti.update_priority_unit_fail')),
      );
      return;
    }

    await getLessons();


    toast?.current?.show(
      toastMessage(t('lesson.noti.update_priority_unit_success')),
    );
    setIsEditPriority(false);
    setLoadingGlobal(false)
  };

  const handlePriorityChange = (id: number, newPriority: number) => {
    const updatedLessons = lessons.data?.map((lesson: any) =>
      lesson.id === id ? { ...lesson, priority: newPriority } : lesson,
    );
    setLessons({ data: updatedLessons, pagination: currentPagination });
    validatePriorities(updatedLessons, id, t, toast, setLessonErrors);
  };

  useEffect(() => {
    getLessons();
  }, [query]);

  return (
    <>
      <CustomDataTable
        wrapClassName="mt-[20px]"
        values={lessons?.data ?? []}
        colums={LessonListColumns(
          t,
          handleEditLesson,
          handleEditStatusLesson,
          handleOpenProgress,
          isDisabledEdit,
          isEditPriority,
          onSavePriority,
          onEditPriority,
          handlePriorityChange,
          lessonErrors,
        )}
        pagination={paginationComputed}
        onPaginationChanged={onPaginationChanged}
        reorderableRows={!isDisabledEdit}
        onRowReorder={onRowReorder}
      />
      <DialogCustom
        header={t('common.dialog.header')}
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
        footer={
          <div className="field">
            <div className="flex justify-end">
              <ButtonCustom
                type="button"
                className="cancle-button p-button-sm mr-2"
                severity="secondary"
                onClick={() => setDialogVisible(false)}
              >
                {t('common.dialog.cancel')}
              </ButtonCustom>
              <ButtonCustom
                type="button"
                onClick={() =>
                  handleEditStatusLesson(
                    selectedLesson.isActive,
                    selectedLesson.rowData,
                  )
                }
              >
                {t('common.dialog.ok')}
              </ButtonCustom>
            </div>
          </div>
        }
      >
        {selectedLesson?.isActive
          ? t('lesson.message_on')
          : t('lesson.message_off')}
      </DialogCustom>
    </>
  );
};

export default LessonList;
