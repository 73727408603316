import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LessonFormSearchType, lessonSearchFormSchema } from "@/pages/lesson/schema"
import { TreeNode } from "primereact/treenode";
import { TreeSelectProps } from "primereact/treeselect";
import useSearch from "@/hooks/useSearch";
import ButtonCustom from "@/components/common/ui/button";
import { InputTextCustomForm } from "@/components/common/ui/input/Input";
import { TreeSelectCustomForm } from "@/components/common/ui/treeSelect";

const LessonFormSearch: React.FC = () => {
  const [t] = useTranslation('');
  const [loading, setLoading] = useState<boolean>();
  const formMethods = useForm<LessonFormSearchType>({
    mode: 'onChange',
    resolver: yupResolver(lessonSearchFormSchema),
    shouldFocusError: false,
    defaultValues: {
      name: '',
      status: 'all',
    },
  });

  const customValueTemplate = (selectedNodes: TreeNode | TreeNode[], props: TreeSelectProps) => {
    if (Array.isArray(selectedNodes) && selectedNodes.length > 0) {
      return (
        <>{t('user_list.status_label')}{selectedNodes[0].label}</>
      )
    }
    
    const defaultOption = props.options?.find((option) => option.key === props.value);
    return (
      <>
        {t('user_list.status_label')}{ defaultOption?.label }
      </>
    )
  };

  const { handleSearch, query } = useSearch();
  const { handleSubmit, getValues, setValue, reset } = formMethods;

  async function onSubmit() {
    setLoading(true);
    let status = getValues('status');
    status = status === 'all' ? '' : status;
    handleSearch({...getValues(), status });
    setLoading(false);
  }

  const getAllFieldNames = () => {
    const values = getValues();
    return Object.keys(values);
  };

  function handleReset() {
    reset()
    onSubmit();
  }

  function mapValueFromQuery() {
    getAllFieldNames().forEach((field: any) => {
      const val = query[field];
      setValue(field, val);
    })
  }

  useEffect(() => {
    mapValueFromQuery()
  }, [])


  return (
    <>
      <FormProvider {...formMethods}>
        <form className="mt-[16px] flex" onSubmit={handleSubmit(onSubmit)}>
          <div className="search-input w-full relative">
            <span className='pi pi-search absolute top-[14px] left-[14px] text-gray-500 text-[15px] z-40' />
            <InputTextCustomForm name="name" className='!pl-[40px]' placeholder={t('lesson_list.name')} />
          </div>
          <TreeSelectCustomForm
            name="status"
            wrapClass="ml-4 w-[193px]"
            panelClassName='tree-hide-header tree-disabled-unselect'
            optionsStatic={[
              { key: 'all', label: t('common.status.all') },
              { key: 'active', label: t('common.status_other.active') },
              { key: 'inactive', label: t('common.status_other.inactive') },
            ]}
            valueTemplate={customValueTemplate}
          />
          <div className="action flex ml-4">
            <ButtonCustom
              type="button"
              severity="secondary"
              onClick={() => handleReset()}
            >
              <span className='pi pi-refresh' />
            </ButtonCustom>
            <ButtonCustom type="submit" disabled={loading} className="ml-2">
              <span className='pi pi-search' />
            </ButtonCustom>
          </div>
        </form>
      </FormProvider>
    </>
  );
}

export default LessonFormSearch;